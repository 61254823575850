var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isLoading)?_c('v-card',{attrs:{"width":"100vw","height":"90vh","elevation":"0"}},[_c('v-container',{attrs:{"fill-height":""}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","justify":"center","align":"center"}},[_c('v-progress-circular',{attrs:{"width":5,"size":100,"color":"primary","indeterminate":""}})],1),_c('v-col',{attrs:{"cols":"12","justify":"center","align":"center"}},[(_vm.loadingStatus == '')?_c('div',[_vm._v(" Cargando ... ")]):_c('div',[_vm._v(" "+_vm._s(_vm.loadingStatus)+" ")])])],1)],1)],1):_c('div',[(_vm.contactAllocation === null || _vm.contactAllocation.length === 0)?_c('MessageTypeAssignationVue'):(_vm.realEstateGroup && _vm.realEstateGroup.assignment_method === 0)?_c('MessageTypeAssignationVue'):_c('div',{staticClass:"pa-5"},[_c('h2',{staticClass:"text-h5"},[_vm._v(" Carrouseles de asignación ")]),_vm._l((_vm.contactAllocation),function(location,index){return _c('div',{key:index},[_c('v-row',{staticClass:"pt-10 px-10",attrs:{"justify":"space-between","align":"center"}},[_c('v-col',{staticClass:"pa-5"},[_c('v-row',[_c('div',{staticClass:"text-h5 pb-2"},[_vm._v(" "+_vm._s(location.name)+" "),(
                    location.contact_list.every(
                      contact => contact.status === 2
                    )
                  )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"color":"pink accent-3","outlined":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-alert-decagram ")]),_c('span',[_vm._v("Asesores inactivos")])],1)]}}],null,true)},[_c('div',[_vm._v(" Los Leads que ingresen al sistema estarán sin asignar ")]),_c('div',[_vm._v("debido a que no hay asesores activos")])]):_vm._e()],1),(location.name === 'General')?_c('div',{staticClass:"mx-2"},[_c('HelperMessage',{staticClass:"mx-2",scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('span',[_vm._v(" Los Leads que ingresen al sistema que no especifiquen el desarrollo de interés, se asignará a un asesor que pertenezca a esta tabla. ")])]},proxy:true}],null,true)},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-help-circle-outline ")])],1)],1):_vm._e()]),(location.name_aliases && location.name_aliases.length > 0)?_c('v-row',{staticClass:"aliases-container"},[_c('div',{staticClass:"container-tags-name"},[_c('v-icon',{attrs:{"left":"","color":"#000000de"}},[_vm._v("mdi-label")]),_vm._v(" Aliases: ")],1),_vm._l((location.name_aliases),function(tag,index){return _c('v-chip',{key:index,attrs:{"outlined":"","color":_vm.colorComputed,"small":""}},[_vm._v(" "+_vm._s(tag)+" ")])})],2):_vm._e()],1),(
              _vm.contactListToAdd(_vm.contactList, location.contact_list).length > 0
            )?_c('div',{staticClass:"pa-5"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"blue","outlined":"","dark":""}},'v-btn',attrs,false),on),[_vm._v(" + Añadir asesor ")])]}}],null,true)},[_c('v-list',_vm._l((_vm.contactListToAdd(
                    _vm.contactList,
                    location.contact_list
                  )),function(item,index){return _c('div',{key:index},[_c('v-btn',{staticClass:"pa-2 text-capitalize subtitle-2",attrs:{"block":"","elevation":"0"},on:{"click":function($event){return _vm.addToAllocation(item, location._id)}}},[_c('div',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-spacer')],1)],1)}),0)],1)],1):_c('div',[_c('v-btn',{staticClass:"my-10 px-5",attrs:{"color":"black","disabled":"","outlined":"","light":""}},[_vm._v(" No hay Asesores para añadir. ")])],1),_c('ModalLogsAllocation',{attrs:{"allocationId":location._id}})],1),(_vm.getTotalTable(location, location.contact_list) > 0)?_c('v-data-table',{staticClass:"elevation-1 blue-grey darken-4 black--text",attrs:{"dense":"","dark":"","hide-default-footer":"","headers":_vm.headers,"items":location.contact_list,"items-per-page":location.contact_list.length},scopedSlots:_vm._u([{key:"body",fn:function(props){return _vm._l((props.items),function(item,index){return _c('tr',{key:index,class:_vm.coloBackground(item, location)},[(item.status !== 0)?_c('td',[(
                    _vm.nextAssign(location) === item.idContact &&
                      !_vm.guardInProcess(location.contact_list)
                  )?_c('v-icon',{staticClass:"page__grab-icon",attrs:{"large":"","color":"green darken-3","light":""}},[_vm._v(" mdi-play ")]):_vm._e(),(
                    _vm.guardInProcess(location.contact_list)?.idContact ===
                      item.idContact
                  )?_c('v-col',{attrs:{"justify":"center","align":"center"}},[_c('IndicatorCurrentGuard')],1):_vm._e()],1):_vm._e(),(item.status !== 0)?_c('td',[_vm._v(" "+_vm._s(index + 1)+" ")]):_vm._e(),(item.status !== 0)?_c('td',[_vm._v(" "+_vm._s(_vm.getDataById(item.idContact).name)+" ")]):_vm._e(),(item.status !== 0)?_c('td',[_vm._v(" "+_vm._s(_vm.getDataById(item.idContact).email)+" ")]):_vm._e(),(item.status !== 0)?_c('td',[(
                    _vm.getDataById(item.idContact) &&
                      _vm.getDataById(item.idContact).performance
                  )?_c('div',[_vm._v(" "+_vm._s(_vm.getDataById(item.idContact).performance.total)+" ")]):_vm._e()]):_vm._e(),(item.status !== 0)?_c('td',[_c('ColumnGuard',{attrs:{"idAllocation":location._id,"idContact":item.idContact,"time_range":item.time_range,"current_time":_vm.getCurrentTime,"contact_list":location.contact_list},on:{"save-guard":time_range =>
                      _vm.saveGuard(time_range, item.idContact, location)}})],1):_vm._e(),(item.status !== 0)?_c('td',[_c('HelperMessage',{scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('div',{staticClass:"py-2 warning--text"},[_vm._v(" Cambiar disponibilidad ")]),_c('div',[_vm._v("Nota:")]),_c('div',[_vm._v(" Desmarcar la casilla afectará al asesor de forma que "),_c('strong',[_vm._v("no podrá")]),_vm._v(" recibir Leads ")]),_c('div',[_vm._v(" de "),_c('strong',[_vm._v("ninguna tabla")]),_vm._v(" en la que este asignado hasta que se vuelva a marcar. ")])]},proxy:true}],null,true)},[_c('v-checkbox',{attrs:{"disabled":_vm.getDataById(item.idContact).status === 0 ||
                        _vm.getDataById(item.idContact).status === 3,"input-value":_vm.disponibility(_vm.getDataById(item.idContact).status),"color":"indigo darken-3","light":"","hide-details":""},on:{"change":function($event){_vm.changeDisponibility(
                        location,
                        _vm.getDataById(item.idContact),
                        _vm.getDataById(item.idContact).status
                      )}}})],1)],1):_vm._e(),(item.status !== 0)?_c('td',[_c('HelperMessage',{scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('div',{staticClass:"py-2 red--text text--accent-1"},[_vm._v(" Eliminar asesor de esta tabla. ")]),_c('div',[_vm._v("Nota:")]),_c('div',[_vm._v(" - Al eliminarlo dejará de recibir Leads de este desarrollo/tabla. ")]),_c('div',[_vm._v(" Puedes añadirlo nuevamente en otro momento para que todo ")]),_c('div',[_vm._v(" vuelva a la normalidad. ")])]},proxy:true}],null,true)},[_c('v-btn',{attrs:{"icon":"","light":""},on:{"click":function($event){_vm.removeFromAllocation(
                        _vm.getDataById(item.idContact),
                        location._id
                      )}}},[_c('v-icon',{attrs:{"light":"","small":""}},[_vm._v(" mdi-close ")])],1)],1)],1):_vm._e()])})}}],null,true)}):_c('v-data-table',{staticClass:"elevation-4 blue-grey darken-4 black--text",attrs:{"dark":"","hide-default-footer":"","headers":_vm.headers,"items":[]},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('div',[_vm._v(" No hay asesores asignados a esta tabla ")])])]},proxy:true}],null,true)})],1)})],2),_c('ListIndicators',{staticClass:"sticky_notes"})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }