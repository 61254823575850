<template>
  <div>
    <!-- Botón para abrir el modal -->
    <v-btn color="primary" @click="dialog = true">Ver Logs</v-btn>

    <!-- Modal con la lista de logs -->
    <v-dialog v-model="dialog" max-width="600" scrollable>
      <v-card>
        <v-card-title class="headline">
          <v-icon left>mdi-format-list-bulleted</v-icon>
          Historial de Logs
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text v-if="logs.length > 0">
          <v-list dense>
            <v-list-item v-for="log in logs" :key="log._id">
              <v-col>
                <v-list-item-content class="listLog pl-4">
                  <div class="v-alert__border v-alert__border--left primary" />
                  <v-row>
                    <v-col cols="12" sm="8">
                      <span>{{ formatLog(log) }}</span>
                      <v-list-item-subtitle>{{
                        formatDate(log.createdAt)
                      }}</v-list-item-subtitle>
                    </v-col>
                    <v-col>
                      <v-chip small :color="getLogTypeColor(log.type)" dark>
                        <!-- Icono y texto del tipo de log -->
                        <v-icon small class="mr-2">{{
                          getLogTypeIcon(log.type)
                        }}</v-icon>
                        <span>{{ getLogTypeText(log.type) }}</span></v-chip
                      >
                    </v-col>
                  </v-row>
                </v-list-item-content>
                <v-divider></v-divider>
              </v-col>
            </v-list-item>
          </v-list>
        </v-card-text>

        <!-- Loading -->
        <v-card-text v-else-if="loading">
          <v-row justify="center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-row>
        </v-card-text>

        <!-- Mensaje si no hay logs -->
        <v-card-text v-else>
          <v-alert
            class="mt-4"
            outlined
            color="warning"
            icon="mdi-alert"
            border="left"
            prominent
          >
            No hay logs para mostrar
          </v-alert>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getLogsByAllocation } from "../../../api/contacts-api";
export default {
  name: "ModalLogsAllocation",
  props: {
    allocationId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      logs: [],
      loading: false
    };
  },
  mounted() {},
  watch: {
    dialog(val) {
      if (val) this.getLogs();
    }
  },
  methods: {
    formatDate(date) {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric"
      };
      return new Date(date).toLocaleDateString("es-ES", options);
    },
    getLogTypeColor(type) {
      switch (type) {
        case "disponibility":
          return "blue";
        case "assignation":
          return "green";
        default:
          return "grey";
      }
    },
    getLogTypeIcon(type) {
      switch (type) {
        case "disponibility":
          return "mdi-account-check";
        case "assignation":
          return "mdi-account-multiple";
        default:
          return "mdi-account";
      }
    },
    getLogTypeText(type) {
      switch (type) {
        case "disponibility":
          return "Disponibilidad";
        case "assignation":
          return "Asignación";
        default:
          return "General";
      }
    },
    async getLogs() {
      try {
        this.loading = true;
        const { Data } = await getLogsByAllocation(this.allocationId);

        this.logs = Data.logs;
      } finally {
        this.loading = false;
      }
    },
    formatLog(log) {
      if (log.type === "assignation") {
        log;
        const { name: nameLead } = log.lead;
        const { name: nameAsigned } = log.asigned;
        return `Se asigno prospecto "${nameLead}" a "${nameAsigned}"`;
      } else if (log.type === "disponibility") {
        const { name } = log.actor;
        const { name: nameAffected } = log.affected;
        const { status } = log;
        return `El usuario "${name}" cambio la disponibilidad de "${nameAffected}" a ${status}`;
      } else {
        return "Log no reconocido";
      }
    }
  }
};
</script>

<style scoped>
.listLog {
  position: relative;
}
</style>
